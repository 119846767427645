import React, { useState } from "react"
import LazyLoad from "vanilla-lazyload"
import loadable from "@loadable/component"

import HomePageContext from "../NewHome/context"
import { AutomatedPolicyUpdatesPageStyled } from "./style"

import Header from "src/componentsV2/sections/Header"
import { Footer } from "../../sections/landing/index.js"

const ContactSalesModal = loadable(() => import("../ContactSalesModal"), {
  resolveComponent: (imported: any) => imported.default,
})

const Integration = loadable(() => import("../NewHome/Integration"), {
  resolveComponent: (imported: any) => imported.default,
})

const HeroSection = loadable(() => import("./HeroSection"), {
  resolveComponent: (imported: any) => imported.default,
})

const WorkSmarterNotHarder = loadable(() => import("./WorkSmarterNotHarder"), {
  resolveComponent: (imported: any) => imported.default,
})

const SaveTime = loadable(() => import("./SaveTime"), {
  resolveComponent: (imported: any) => imported.default,
})

const SetAndForget = loadable(() => import("./SetAndForget"), {
  resolveComponent: (imported: any) => imported.default,
})

const index = () => {
  const [isContactSalesOpenModal, setIsContactSalesOpenModal] = useState(false)
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    if (!ref.current && window !== undefined) {
      ref.current = new LazyLoad()
    }
    if (ref.current && window !== undefined) {
      ref.current.update()
    }
  }, [ref])

  const openContactSalesModal = () => {
    setIsContactSalesOpenModal(true)
  }

  return (
    <HomePageContext.Provider value={{ openContactSalesModal }}>
      <AutomatedPolicyUpdatesPageStyled>
        <Header />
        <HeroSection fallback={<div />} />
        <SaveTime fallback={<div />} />
        <SetAndForget fallback={<div />} />
        <Integration fallback={<div />} />
        <WorkSmarterNotHarder fallback={<div />} />
        <Footer />
      </AutomatedPolicyUpdatesPageStyled>
      {isContactSalesOpenModal && (
        <ContactSalesModal
          onClose={() => setIsContactSalesOpenModal(false)}
          isOpen={isContactSalesOpenModal}
        />
      )}
    </HomePageContext.Provider>
  )
}

export default index
